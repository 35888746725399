var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "group-list" },
    [_c("group-list", { attrs: { groupList: _vm.groupList } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
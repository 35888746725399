<template>
  <div class="group-list">
    <group-list :groupList="groupList"></group-list>
  </div>
</template>
<script>
import groupList from "@/components/goods/groupList";
import { getGroupList } from "@/services/goodsApi.js";
export default {
  name: "groupListBox",
  data() {
    return {
      group_buy_item_id: this.$route.query.group_buy_item_id,
      page: 0,
      listRows: 10,
      groupList: []
    };
  },
  components: {
    groupList
  },
  methods: {
    async getList() {
       try{
         let data = await getGroupList(this.group_buy_item_id, this.page, this.listRows)
         if(data.code === 0){
           let list = data.data.data
           this.groupList = [...this.groupList, ...list];
         }
       }catch(err){
         throw err;
       }
    }
  },
  created() {
    this.getList();
  }
};
</script>
<style scoped lang="less">
.group-list {
  min-height: 100%;
  background: #fff;
}
</style>